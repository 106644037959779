import { useMediaQuery, useTheme, Container, Alert, Typography, AlertTitle, Stepper, StepButton, Step, Box, Stack, Grid, Divider } from "@mui/material"
import { Page } from "../../components"
import { AppIndexStyles } from "./styles"
import { UserFormStepBuilder } from "../../builders/user-form-step.builder"

export const PurchasedCompletedCreditCard = () => {
    const theme = useTheme();
    
    const titlePage = {
        'abrir.cadastromei.org': 'Cadastro Mei',
        'abrir.meicontabil.com': 'Mei Contábil',
        'abrir.portalmeumei.com': 'Portal Meu Mei',
        'abrir.aberturamei.org': 'Abertura Mei',
        'abrir.empreendedorcnpj.com.br': 'Empreendedor CNPJ',
        'abrir.cnpjmei.com.br': 'CNPJ Mei',
        'abrir.cnpjmei.org': 'CNPJ Mei'
    }[window.location.hostname] || 'Cadastro Mei'

    return (
        <Page title={titlePage + ' - Pagamento Realizado'}>
            <Container id="purchased-creditcard" maxWidth={false} sx={{ mt: 5 }}>
                <Container fixed style={AppIndexStyles.stepperContainer}>
                    <Stepper activeStep={UserFormStepBuilder.form.steps.length} style={{ marginTop: 10 }}>
                        {UserFormStepBuilder.form.steps.map((step) => 
                            <Step key={step?.key}>
                                <StepButton color="inherit" style={{ fontFamily: 'Inter' }}>
                                {step?.title}
                                </StepButton>
                            </Step>
                        )}
                    </Stepper >
                </Container>
                <Container fixed sx={{ mb: 2 }}>
                    <Alert severity="success">  
                        <AlertTitle>Seu pedido foi realizado com sucesso!</AlertTitle>
                        <Typography mt={2} color={'#155915'} fontWeight={500} fontSize={16}>
                            Estamos analisando seus dados e em breve você receberá um e-mail no seu endereço informado com todos os detalhes do seu pedido.
                        </Typography>
                    </Alert>
                </Container>

            </Container>
        </Page>
    )
}