import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from '@mui/lab';
import { useTheme, useMediaQuery, Alert, MobileStepper, AlertTitle, Box, Button, Checkbox, Container, Dialog, DialogContent, Divider, Grid, IconButton, Stack, Step, StepButton, Stepper, Tooltip, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { When } from 'react-if';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cards from 'react-credit-cards';
import TagManager from 'react-gtm-module';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CreditCardFormBuild } from '../../builders/credit-card-form.builder';
import { UserFormStepBuilder } from '../../builders/user-form-step.builder';
import { Page } from '../../components';
import FormBuilder from '../../components/form-builder';
import { CreateCnpjStepOne } from '../../components/forms/create-cnpj-step-form/step-1';
import { CreateCnpjStepTwo } from '../../components/forms/create-cnpj-step-form/step-2';
import { CreateCnpjStepThree } from '../../components/forms/create-cnpj-step-form/step-3';
import { CredentialsStepFour } from '../../components/forms/create-cnpj-step-form/step-4';
import { RegexFactory } from '../../factory/regex.factory';
import { requestViaCep } from '../../helpers/useViaCep';
import { api } from '../../http';
import { RootState, store } from '../../store';
import { DefaultRes, InputForm } from '../../types';
import { Customer, GetPaymentDetailsResponse, RequestCustomerPaymentResponse } from '../../types/customer.types';
import { FormOnboard } from '../../types/form-onboard.types';
import { stepOneValidation, stepThreeOnlyPersonalValidation, stepTwoValidation, stepTwoValidationCnpj, stepTwoValidationDeclaration, stepTwoValidationInstallment } from '../../validations/form.validations';
import { AppIndexStyles } from './styles';
import useInterval from "../../hooks/useInterval";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'
import { PixPayment } from './pix-payment';
import _ from 'lodash';

const Home = ({  }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { form, actualStep: ac, customer } = useSelector((state: RootState) => state.onboard);
  const [actualStep, setActualStep] = useState<number>(ac);
  const [paymentType, setPaymentType] = useState<'credit_card' | 'bill' | 'pix'>();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [paymentData, setPaymentData] = useState<any>();
  const [termLink, setTermLink] = useState('');
  const [loading, setLoading] = useState({
    payment: false,
    createCustomer: false,
  });
  const [gtmClass, setGtmClass] = useState<undefined | string>(undefined);
  const [done, setDone] = useState(false);
  const [modal, setModal] = useState({
    pay: false,
    terms: false,
  });
  const [configs, setConfigs] = useState({} as any);
  const location = useLocation();
  const [userId, setUserId] = useState(null || String);
  const [pagePixPayment, setPagePixPayment] = useState(false);

  const [searchParams] = useSearchParams();
	const sidParams = searchParams.get('sid') ?? 'create';
 

  const stepsValidationService: Record<string, any> = {
    create: stepTwoValidation,
    cancel: stepTwoValidationCnpj,
    declaration: stepTwoValidationDeclaration,
    installment: stepTwoValidationInstallment,
    update: stepTwoValidation,
  }

  const validationSchemaPerStep: Record<number, any> = {
    0: stepOneValidation,
    1: stepsValidationService[sidParams],
    2: stepThreeOnlyPersonalValidation,
  }

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const toggleLoading = (key: keyof typeof loading, value: boolean) => setLoading((l) => ({ ...l, [key]: value }));
  const toggleModal = (key: keyof typeof modal, value: boolean) => setModal((l) => ({ ...l, [key]: value }));

  const formik = useFormik<FormOnboard>({
    validationSchema: validationSchemaPerStep[actualStep],
    initialValues: {
      ...form,
    },
    onSubmit: async (values) => {}
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    setValues,
    getFieldProps,
    validateForm
  } = formik;

  const pushUtms = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);

      const data = {
        acid: searchParams.get('acid'),
        utms: {
          rcpSource: searchParams.get('rcp_source'),
          rcpMedium: searchParams.get('rcp_medium'),
          rcpCampaign: searchParams.get('rcp_campaign'),
          rcpContent: searchParams.get('rcp_content'),
          rcpTerm: searchParams.get('rcp_term')
        }
      }

      if (!data.acid) {
        return;
      }

      const resp = await api.post('/utms', { ...data });

      console.log('Send utms:', resp.data);
    } catch (err) {
      console.log('Error send utms:', err)
    }
  }

  const getDiscount = (): number => {
    const searchParams = new URLSearchParams(location.search);

    const discount = {
      '0OFF': 0,
      '5OFF': 0.05,
      '10OFF': 0.1,
      '15OFF': 0.15,
    }[searchParams.get('discount') || '0OFF'] || 0;

    return discount;
  }

  const pushDataLayer = async (events: string[]) => {
    try {
      const searchParams = new URLSearchParams(location.search);

      events.forEach((event) => {
        let data: any = {
          fullName: values.fullName,
          email: values.email,
          phone: values.phone,
          cpf: values.cpf,
          rg: String(values.rg),
          birthDate: values.birthdate,
          rgEmissor: values.rgEmissor,
          rgStateEmissor: values.rgStateEmissor,
          mothername: values.mothername,
          user_id: userId,
          aff: searchParams.get('aff'),
          utm_source: searchParams.get('utm_source'),
          utm_medium: searchParams.get('utm_medium'),
          utm_campaign: searchParams.get('utm_campaign'),
          utm_content: searchParams.get('utm_content'),
          utm_term: searchParams.get('utm_term')
        };

        if (['lead_intermediario', 'add_shipping_info', 'add_payment_info', 'purchase'].includes(event)) {
          switch(sidParams) {
            case 'create':
              data = {
                ...data,
                // fantasyName: values.fantasyName,
                socialCapital: values.socialCapital,
                principalOcupation: values.principalOcupation,
                secondaryOcupations: values.secondaryOcupations,
                actingType: values.actingType,
              }
              break;
            case 'cancel' :
              data = {
                ...data,
                cnpj: values.cnpj,
              }
              break;
            case 'declaration': 
              data = {
                ...data,
                cnpj: values.cnpj,
                declaration_year: values.declaration_year,
                annual_billing: values.annual_billing
              }
              break;
            case 'installment': {
              data = {
                ...data,
                cnpj: values.installmentCnpj,
              }
              break;
            }
            case 'update': {
              data = {
                ...data,
                // fantasyName: values.fantasyName,
                socialCapital: values.socialCapital,
                principalOcupation: values.principalOcupation,
                secondaryOcupations: values.secondaryOcupations,
                actingType: values.actingType,
              }
              break;
            }
          }
        }

        if (['add_shipping_info', 'add_payment_info', 'purchase'].includes(event)) {
          let zipcode = String(values.zipcode).replace(/[^0-9]/g, '')

          data = {
            ...data,
            zipcode: zipcode.slice(0,5)+'-'+zipcode.slice(5),
            street: values.street,
            number: String(values.number),
            complement: values.complement,
            district: values.district,
            city: values.city,
            state: values.state
          }

          if (!values.personalAddressIsSameComercial && values.comercialAddressZipcode) {
            let zipcode = String(values.comercialAddressZipcode).replace(/[^0-9]/g, '')

            data = {
              ...data,
              comercialAddressZipcode: zipcode.slice(0,5)+'-'+zipcode.slice(5),
              comercialAddressStreet: values.comercialAddressStreet,
              comercialAddressNumber: values.comercialAddressNumber,
              comercialAddressComplement: values.comercialAddressComplement,
              comercialAddressDistrict: values.comercialAddressDistrict,
              comercialAddressCity: values.comercialAddressCity,
              comercialAddressState: values.comercialAddressState,
            }
          }
        }
        
        if (['add_payment_info', 'purchase'].includes(event)) {
          data = {
            ...data,
            transactionId: paymentData.txid,
            value: String(configs.price * (1 - getDiscount())),
            payment_type: paymentType === 'pix' ? 'pix' : 'cartao',
            login_govbr: values.cpf || '',
            senha_govbr: values.govPassword || ''
          }
        }

        console.log(event, data)

        TagManager.dataLayer({
          dataLayer: {
            event,
            user_data: data,
            items: [{
              item_name: "Consultoria MEI",
              quantity: 1,
              item_category: "Serviço",
              price: String(configs.price * (1 - getDiscount()))
            }]
          }
        });
      });
    } catch (e) {
      console.log('DataLayer:', e);
    }
  }

  const requestSendMetrics = async (data = {}) => {
    try {
      // const body: any = {}

      // body.actingType = (values?.actingType as any)?.value;
      // body.principalOcupation = (values?.principalOcupation as any)?.value;
      // body.secondaryOcupations = values?.secondaryOcupations?.map((o: any) => o.value).join(',');

      // const searchParams = new URLSearchParams(location.search);

      // const utms = {
      //   aff: searchParams.get('aff'),
      //   utm_source: searchParams.get('utm_source'),
      //   utm_medium: searchParams.get('utm_medium'),
      //   utm_campaign: searchParams.get('utm_campaign'),
      //   utm_content: searchParams.get('utm_content'),
      //   utm_term: searchParams.get('utm_term')
      // }

      // const res = await axios.post(
      //   'https://n8n.condeads.com/webhook-test/cadastromei',
      //   JSON.stringify({ ...values, ...body, ...data }), {
      //     headers: {
      //       'Content-type': 'application/json; charset=UTF-8'
      //     },
      //   }
      // );

      // const res = await fetch('https://n8n.condeads.com/webhook/cadastromei', {
      //   method: 'POST',
      //   body: JSON.stringify({ ...values, ...body, ...data, ...utms }),
      //   headers: {
      //     // 'content-type': 'application/json; charset=UTF-8'
      //   },
      // });
      // console.log('Send metrics:', await res.json());
    } catch (err) {
      // console.log('Error send metrics:', err)
    }
  }

  const createCustomer = async () => {
    toggleLoading('createCustomer', true);
    try {
      const searchParams = new URLSearchParams(location.search);

      if (searchParams.get('continue')) {
        toggleLoading('createCustomer', false);
        return setActualStep(step => step + 1);
      }

      const sendData = {
        ...values,
        sid: searchParams.get('sid'),
      }

      if (values.principalOcupation) {
        sendData.principalOcupation = (values.principalOcupation as any)?.value;
      } 
      if (values.secondaryOcupations) {
        sendData.secondaryOcupations = values.secondaryOcupations.map((o: any) => o.value);
      }
      if (values.actingType) {
        sendData.actingType = (values.actingType as any)?.value
      }

      const utms = {
        aff: searchParams.get('aff'),
        utmSource: searchParams.get('utm_source'),
        utmMedium: searchParams.get('utm_medium'),
        utmCampaign: searchParams.get('utm_campaign'),
        utmContent: searchParams.get('utm_content'),
        utmTerm: searchParams.get('utm_term'),
        sid: searchParams.get('sid')
      }

      const res = await api.post<DefaultRes<Customer>, AxiosResponse<DefaultRes<Customer>>>('/customers', {
         customerData: {
          ..._.omit(sendData, ['cardNumber', 'holderName', 'expirationDate', 'securityCode']),
          ...utms
         }
      });
      
      toggleLoading('createCustomer', false);

      if (res.data.isValid) {
        store.dispatch.onboard.setCustomer(res.data.result);
        setActualStep(step => step + 1);
      }

    } catch (err) {
      toggleLoading('createCustomer', false);
    }
  }

  const generatePayment = async () => {
    toggleLoading('payment', true);
    
    try {
      let payload: any = { type: paymentType };

      if (paymentType === 'credit_card') {
        payload.paymentData = {
          holderName: values.holderName,
          cardNumber: values.cardNumber,
          expirationDate: values.expirationDate,
          securityCode: values.securityCode,
          installmentQuantity: values.installmentQuantity
        };
      }

      const searchParams = new URLSearchParams(location.search);

      const res = await api.post<
        RequestCustomerPaymentResponse, 
        AxiosResponse<RequestCustomerPaymentResponse>
      >(`/payments/${customer?.uuid}`, { ...payload, discount: searchParams.get('discount') });

      toggleLoading('payment', false);

      if (res.data.result.status === 'failed' || (res.data.result.error && res.data.result.message === 'E_PAYMENT')) {
        toast(res.data.result.text, { type: 'error' });
        
        return;
      }

      if (res.data.isValid) {
        setPaymentData({ ...res.data.result, type: paymentType });
        if (paymentType === 'pix') {
          // toggleModal('pay', true);

          requestSendMetrics({ paymentType: paymentType === 'pix' ? 'PIX' : 'Cartão', value: String(configs.price * (1 - getDiscount())), transaction_id: res.data?.result?.txid });

          setPagePixPayment(true);
        }
        else if (paymentType === 'credit_card' && res.data.result.status === 'complete') {
          setActualStep(4);
          setGtmClass('purchased-card');

          requestSendMetrics({ paymentType: 'Cartão', value: String(configs.price * (1 - getDiscount())), transaction_id: res.data.result?.txid });

          pushDataLayer(['purchase']);
        }
      }

      toggleLoading('payment', false);
    } catch (err) {
      toggleLoading('payment', false);
    }

    return;
  }

  const handleNextStep = async () => {
    const result = await validateForm(values);

    const stepWithErrors = Object.keys(result)?.length > 0;

    if (!stepWithErrors) {
      if (actualStep === 0) {
        pushDataLayer(['lead_basico']);
      }

      if (actualStep === 1) {
        pushDataLayer(['lead_intermediario'])
      }

      if (actualStep === 2) {
        await createCustomer();
        pushDataLayer(['add_shipping_info']);
        return;
      }

      if (actualStep === 3) {
        await generatePayment();
        pushDataLayer(['add_payment_info']);
        return;
      }

      if(actualStep === 4) {
        await api.patch(`/customers/${customer.uuid}`, { customerData: { cpf: values.cpf, govPassword: values.govPassword }});
        setDone(true);
        toast('Tudo certo, agora basta aguardar o contato e a finalização da abertura do seu CNPJ!', { type: 'success' });
        navigate(`/${gtmClass || 'purchased-pix'}`);
        return;
      }

      setActualStep(step => step + 1);

      uuidv4();
    }

    // requestSendMetrics()
  };

  const handlePreviousStep = () => {
    if (pagePixPayment) {
      return setPagePixPayment(false);
    }
    setActualStep(step => step - 1);
  };

  useEffect(() => {
    toggleLoading('payment', false);
    store.dispatch.onboard.setForm(values);
  }, [values]);

  useEffect(() => {
    store.dispatch.onboard.setActualStep(actualStep);
  }, [actualStep]);


  useEffect(() => {
    if (RegexFactory.onlyNumbers(values?.zipcode!)?.length === 8) {
      (async () => {
        const address = await requestViaCep(RegexFactory.onlyNumbers(values?.zipcode!));
        setValues({
          ...values,
          ...address,
        });
      })();
    }
  }, [values?.zipcode])

  useEffect(() => {
    if (RegexFactory.onlyNumbers(values?.comercialAddressZipcode!)?.length === 8) {
      (async () => {
        const address = await requestViaCep(RegexFactory.onlyNumbers(values?.comercialAddressZipcode!));
        setValues({
          ...values,
          comercialAddressCity: address?.city,
          comercialAddressComplement: address?.complement,
          comercialAddressDistrict: address?.district,
          comercialAddressStreet: address?.street,
          comercialAddressState: address?.state,
          comercialAddressZipcode: address?.zipcode,
        });
      })();
    }
  }, [values?.comercialAddressZipcode])

  useInterval(() => {
    (async () => {
      if (paymentData && paymentData.type === 'pix' && actualStep !== 4 && actualStep !== 5) {
        const timestamp = Date.now();
        const res = await api.get<GetPaymentDetailsResponse, AxiosResponse<GetPaymentDetailsResponse>>(`/payments/${paymentData.txid}?timestamp=${timestamp}`, {
          headers: {
            cache: 'no-store',
          }
        });
      
        if (res.data.result.status === 'complete') {
          toast('Pagamento realizado com sucesso!', { type: 'success' });
          // toggleModal('pay', false);
          setPagePixPayment(false);
          setGtmClass('purchased-pix');
          setActualStep(4);
          await requestSendMetrics()
          
          pushDataLayer(['purchase']);
        }
      }
    })(); 
  }, 5000);

  useEffect(() => {
    if (actualStep === 3) {
      let body = {}
      if (values.comercialAddressZipcode) {
        body = {
          comercialAddressCity: values?.comercialAddressCity,
          comercialAddressComplement: values?.comercialAddressComplement,
          comercialAddressDistrict: values?.comercialAddressDistrict,
          comercialAddressStreet: values?.comercialAddressStreet,
          comercialAddressState: values?.comercialAddressState,
          comercialAddressZipcode: values?.comercialAddressZipcode,
        }
      }
 
      // requestSendMetrics({
      //   ...body,
      // });
    }
  }, [actualStep]);

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('continue')) {
        try {
          const res = await api.get(`/customers/${searchParams.get('continue')}`);

          if (res.data.isValid) {

            for (const [key, value] of Object.entries(res.data.result?.customer_data)) {
              if (key  === 'principalOcupation') {
                setFieldValue('principalOcupation', { value: value, label: value });
              }

              else if (key === 'secondaryOcupations') {
                setFieldValue('secondaryOcupations', (value as any).split(',').map((o: any) => ({ value: o, label: o })));
              }

              else if (key === 'actingType') {
                setFieldValue('actingType', { value: value, label: value });
              }

              else {
                setFieldValue(key, value);
              }
            }

            store.dispatch.onboard.setCustomer({
              ...res.data.result,
              ...res.data.result?.customer_data
            });

            setActualStep(3);
          }
        } catch (e) {
          console.log(e)
        }
      }

      if (userId !== null) {
        setUserId(uuidv4());
      }

      const res = await api.get('/payments/configs');

      if (res.data.isValid) {
        setConfigs(res.data.result);
      }
    })()
  }, [])

  useEffect(() => {
    pushUtms();
  }, []);

  const titlePage = {
    'abrir.cadastromei.org': 'Cadastro Mei',
    'abrir.meicontabil.com': 'Mei Contábil',
    'abrir.portalmeumei.com': 'Portal Meu Mei',
    'abrir.aberturamei.org': 'Abertura Mei',
    'abrir.empreendedorcnpj.com.br': 'Empreendedor CNPJ',
    'abrir.cnpjmei.com.br': 'CNPJ Mei',
    'abrir.cnpjmei.org': 'CNPJ Mei'
  }[window.location.hostname] || 'Cadastro Mei'

  const stepTwoFormByService: Record<string, number> = {
    create: 0,
    cancel: 1,
    declaration: 2,
    installment: 3,
    update: 0,
  }

  const messageServicePayment: Record<string, string> = {
    create: 'Abertura do CNPJ MEI',
    cancel: 'Baixa do CNPJ MEI',
    declaration: 'Declaração do CNPJ MEI',
    installment: 'Parcelamento MEI',
    update: 'Atualização MEI'
  }

  return (
    <>
      <Page title={titlePage}>
        <Divider />
        <Container id={gtmClass} maxWidth={false} sx={{ mt: isSmallScreen ? 2 : 5 }}>
          <When condition={actualStep !== 5 && !isSmallScreen}>
            <Container fixed style={AppIndexStyles.stepperContainer}>
              <Stepper activeStep={actualStep} style={{ marginTop: 10 }}>
                {UserFormStepBuilder.form.steps.map((step) => 
                  <Step key={step?.key}>
                    <StepButton color="inherit" style={{ fontFamily: 'Inter' }}>
                      {step?.title}
                    </StepButton>
                  </Step>
                )}
              </Stepper >
            </Container>
          </When>

          <When condition={isSmallScreen && actualStep !== 5 && actualStep !== 4}>
            <MobileStepper 
              steps={UserFormStepBuilder.form.steps?.length} 
              activeStep={actualStep}
              nextButton={
                <LoadingButton onClick={handleNextStep} variant='contained' color='success' style={{ color: 'white' }} loading={loading.payment} disabled={actualStep === 3 ? !paymentType || !agreeTerms || pagePixPayment : false}>
                  {actualStep === 3 ? 'Pagar Agora' : 'Próximo'}
                </LoadingButton>
              }
              backButton={
                <Button onClick={handlePreviousStep} variant='outlined' sx={{ mr: 1 }} color='secondary' disabled={loading.createCustomer || actualStep === 0}>
                  Voltar
                </Button>
              }
            />
          </When>
        
          <When condition={actualStep === 4}>
            <Container fixed sx={{ mb: 2 }}>
              <Alert severity="success">  
                <AlertTitle>Seu pedido foi realizado com sucesso!</AlertTitle>
                <Typography mt={2} color={'#155915'} fontWeight={500} fontSize={16}>
                  Estamos analisando seus dados e em breve você receberá um e-mail no endereço <strong>{values?.email}</strong> com todos os detalhes do seu pedido.
                </Typography>
              </Alert>
            </Container>
          </When>

          <When condition={!done}>
            <Container fixed style={{ background: '#F8F9FA', padding: 10, paddingBottom: isSmallScreen ? 50 : 10 }}>
              {UserFormStepBuilder.form.steps.map((step) => (
                <>
                  <When condition={actualStep === 0 && step.key === 0}>
                    {step?.sections?.map((section, sectionIndex) => (
                      <CreateCnpjStepOne
                        stepKey={section?.key}
                        inputs={section?.inputs as InputForm[]}
                        sectionIndex={sectionIndex}
                        label={section?.label} 
                        errors={errors}
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    ))}
                  </When>
                  <When condition={actualStep === 1 && step.key === 1}>
                    {step?.sections?.map((section, sectionIndex) => {
                      return <CreateCnpjStepTwo 
                        stepKey={section?.key}
                        inputs={section?.inputs[stepTwoFormByService[sidParams]] as InputForm[]}
                        sectionIndex={sectionIndex}
                        label={section?.label} 
                        errors={errors}
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    })}
                  </When>
                  <When condition={actualStep === 2 && step.key === 2}>
                    {step?.sections?.map((section, sectionIndex) => (
                      <CreateCnpjStepThree 
                        stepKey={section?.key}
                        inputs={section?.inputs as InputForm[]}
                        sectionIndex={sectionIndex}
                        label={section?.label} 
                        errors={errors}
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    ))}
                  </When>
                  
                  <When condition={actualStep === 4 && step.key === 4}>
                    <div id="purchase-completed">
                      {step?.sections?.map((section, sectionIndex) => (
                        <CredentialsStepFour 
                          stepKey={section?.key}
                          inputs={section?.inputs as InputForm[]}
                          sectionIndex={sectionIndex}
                          label={section?.label} 
                          errors={errors}
                          getFieldProps={getFieldProps}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                        />
                      ))}
                      <Stack display="flex" flexDirection={"row-reverse"} mt={4} mb={2}>
                        <LoadingButton onClick={handleNextStep} variant='contained' color='secondary' loading={loading.createCustomer}>
                          Validar acesso
                        </LoadingButton>
                      </Stack>
                    </div>
                  </When>
                </>
              ))}
              
              <When condition={actualStep === 3 && !pagePixPayment}>
                <Box style={{ margin: 15 }}>
                  <Typography color={'#2D2935'} marginBottom={4} marginTop={2} fontSize={22} fontWeight={600} lineHeight={2} letterSpacing={0.2}>
                    Taxa
                  </Typography>
                  <Box style={{ marginTop: 40, background: 'white', padding: 25 }}>
                    <Stack display="flex" flexDirection={isSmallScreen ? "column" : "row"} justifyContent={isSmallScreen ? "center" : "space-between"}>
                      <Grid item md={4} sm={12} xs={12} mt={isSmallScreen ? 2 : 0}>
                        <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'}>
                          
                        </Typography>
                        <Typography mt={2} color="#737185" fontSize={14} textAlign={'left'}>
                          {
                            messageServicePayment[sidParams]
                          }
                      
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12} mt={isSmallScreen ? 2 : 0}>
                        <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'}>
                          Quantidade
                        </Typography>
                        <Typography mt={isSmallScreen ? 0 : 2} color="#737185" fontSize={14} textAlign={isSmallScreen ? "left" : 'center'}>
                          1
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12} mt={isSmallScreen ? 2 : 0}>
                        <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'}>
                          Subtotal
                        </Typography>
                        <Typography mt={2} color="#737185" fontSize={14} textAlign={'left'}>
                          {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configs.price * (1 - getDiscount()))}
                        </Typography>
                      </Grid>
                    </Stack>
                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                    <Stack display="flex" flexDirection={"row"} justifyContent={"flex-end"}>
                      <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'} mr={2}>
                        Total
                      </Typography>
                      <Typography color="#737185" fontSize={14} textAlign={'left'}>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(configs.price) * (1 - getDiscount()))}
                      </Typography>
                    </Stack>
                  </Box>
                  <Typography color={'#3A3847'} marginBottom={2} marginTop={6} fontSize={16} fontWeight={600}>
                    Formas de pagamento
                  </Typography>
                  <Typography ml={3} mt={1} fontSize={14} fontFamily={'Inter'} fontStyle={'normal'}>
                  Após a compensação da taxa, nossa equipe trabalhará o mais rápido possível para enviar o CNPJ registrado para o seu e-mail cadastrado. Certifique-se que o e-mail informado esta correto.
                  </Typography>

                  <Box style={{ marginTop: 40, marginBottom: 40, background: 'white', padding: 25 }}>                    
                    <Divider style={{ marginBottom: 15, marginTop: 15 }} />

                    <Stack display="flex" flexDirection="row" justifyContent={"flex-start"} alignContent={"center"} alignItems={"center"}>
                      <Checkbox 
                        checked={paymentType === 'credit_card'} 
                        onChange={(_, c) => {
                          if (c === false) {
                            setPaymentType(undefined);
                            return;
                          }

                          setPaymentType('credit_card')
                        }} 
                      />
                      <Stack flexDirection={"row"} display="flex" ml={1}>
                        <img style={{ marginRight: 10 }} src={"/static/illustrations/credit_card.svg"} />
                        <Typography color="#3A3847" fontSize={14} fontWeight={600} fontFamily={'Inter'} fontStyle={'normal'}>
                          Cartão de crédito
                        </Typography>
                      </Stack>
                    </Stack>

                    <When condition={paymentType === 'credit_card'}>
                      <Grid container mt={2} mb={4}>
                        <Grid item md={6}>
                          <FormBuilder
                            errors={errors}
                            touched={touched}
                            values={values}
                            setFieldValue={setFieldValue}
                            inputs={CreditCardFormBuild.form(configs.price * (1 - getDiscount()), configs.creditCardFees)}
                            getFieldProps={getFieldProps}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Cards
                            cvc={values?.securityCode || ''}
                            expiry={values?.expirationDate || ''}
                            name={values?.holderName || ''}
                            number={values?.cardNumber || ''}
                            locale={{ valid: 'válido até'}}
                            placeholders={{ name: 'NOME' }}
                          />
                        </Grid>
                      </Grid>
                    </When>

                    <Divider style={{ marginBottom: 15, marginTop: 15 }} />

                    <Stack display="flex" flexDirection="row" justifyContent={"flex-start"} alignContent={"center"} alignItems={"center"}>
                      <Checkbox 
                        checked={paymentType === 'pix'} 
                        onChange={(_, c) => {
                          if (c === false) {
                            setPaymentType(undefined);
                            return;
                          }

                          setPaymentType('pix')
                        }} 
                      />
                      <Stack flexDirection={"row"} display="flex" ml={1}>
                        <img style={{ marginRight: 10 }} src={"/static/illustrations/ic_outline-pix.svg"} />
                        <Typography color="#3A3847" fontSize={14} fontWeight={600} fontFamily={'Inter'} fontStyle={'normal'}>
                          PIX
                        </Typography>
                      </Stack>
                      <Typography>
                      </Typography>
                    </Stack>

                    {/* <Typography mt={1} fontSize={14} fontFamily={'Inter'} fontStyle={'normal'}>
                      * O pagamento em PIX não pode ser parcelado
                    </Typography> */}
                    <Typography mt={1} fontSize={14} fontFamily={'Inter'} fontStyle={'normal'}>
                      * O processo do seu CNPJ MEI será iniciado após a confirmação do pagamento
                    </Typography>
                    <Typography mt={1} fontSize={14} fontFamily={'Inter'} fontStyle={'normal'}>
                      * Selecione a forma de pagamento e clique em "Pagar Agora"
                    </Typography>
                    <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                  </Box>
                  <Typography style={{ marginBottom: 20 }} fontSize={14} mr={4} ml={1} mt={1} textAlign={'left'} color='#605E70'>
                    Os seus dados pessoais serão utilizados para processar a sua compra, apoiar a sua experiência em todo este site e para outros fins descritos na nossa <a onClick={() => {
                      setTermLink('https://cadastromei.org/politica-de-privacidade');
                      toggleModal('terms', true);
                    }}>política de privacidade.</a>
                  </Typography>
                  <Stack display="flex" flexDirection={"row"} ml={-1} style={{ marginBottom: isSmallScreen ? 70 : 20 }}>
                    <Checkbox checked={agreeTerms} onChange={(e, v) => setAgreeTerms(v)} />
                    <Typography fontSize={14} mr={4} mt={1} textAlign={'left'} color='#605E70'>
                      Li e concordo com os <a onClick={() => {
                      setTermLink('https://cadastromei.org/termos-de-uso');
                      toggleModal('terms', true);
                    }}>termos e condições</a> do site
                    </Typography>
                  </Stack>
                    <When condition={!isSmallScreen}>
                      <LoadingButton sx={{ mb: isSmallScreen ? 5 : 0 }} loading={loading.payment} disabled={!paymentType || !agreeTerms || pagePixPayment} onClick={handleNextStep} variant='contained' color='success' fullWidth style={{ color: 'white' }}>
                        Pagar Agora
                      </LoadingButton>
                    </When>
                </Box>
              </When>

              <When condition={pagePixPayment}>
                <PixPayment
                  data={{
                    price: configs?.price * (1 - getDiscount()),
                    name: values.fullName,
                    dueDate: paymentData?.dueDate,
                    key: paymentData?.key,
                    qrCode: paymentData?.qrCode,
                  }}
                />
              </When>
            </Container>
            <When condition={!isSmallScreen && actualStep !== 4}>
              <Container fixed style={{ padding: 0, paddingBottom: 20 }}>
                <Stack display={'flex'} flexDirection={'row-reverse'} mt={2}>      
                  <When condition={actualStep !== 4 && actualStep !== 3}>
                    <LoadingButton onClick={handleNextStep} variant='contained' color='secondary' loading={loading.createCustomer}>
                      Próximo
                    </LoadingButton>
                  </When>
                  <When condition={actualStep !== 0}>
                    <Button onClick={handlePreviousStep} variant='outlined' sx={{ mr: 1 }} color='secondary' disabled={loading.createCustomer}>
                      Voltar
                    </Button>
                  </When>
                </Stack>
              </Container>
            </When>
          </When>
          
        </Container>
      </Page>

      <Dialog
        open={modal.pay}
        maxWidth={'xl'}
        scroll={'paper'}
        fullWidth={paymentType === 'bill'}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ ml: 3, mb: 2, mt: 2 }} fontSize={18} fontWeight={400} color={'text.default'}>
              Realizar pagamento
            </Typography>
            <Tooltip title="Fechar popup">
              <IconButton
                onClick={() => toggleModal('pay', false)}
                component="span"
                sx={{ mr: 2, height: 30, width: 30, alignSelf: 'center' }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <DialogContent dividers>
          <Stack display="flex" flexDirection="column" justifyContent={"center"} alignItems={'center'} alignContent={"center"}>
            <When condition={paymentType === 'pix' && paymentData}>
              <Typography fontSize={14} mb={2}>
                1 - Clique no botão abaixo para copiar o código <br /><br />
                2 - Abra o aplicativo do seu banco ou instituição financeira e entre na opção Pix <br /><br />
                3 - Na opção Pix Copia e Cola, insira o código copiado ao apertar o botão abaixo "Copiar código"
              </Typography>
              <Button variant="contained" sx={{ mt: 2 }}
                onClick={async () => {
                  await navigator.clipboard.writeText(paymentData?.key);
                  toast(
                    'Código pix copiado com sucesso!',
                    {
                      autoClose: 1500,
                      type: 'success',
                    },
                  );
                }}
              >
                Copiar código
              </Button>
              <img width={String(paymentData?.qrCode).startsWith('data:image/png;base64,') ? 300 : 400} height={String(paymentData?.qrCode).startsWith('data:image/png;base64,') ? 300 : 450} src={paymentData?.qrCode} />
            </When>
            <When condition={paymentType === 'bill' && paymentData}>
              <iframe width={'100%'} height={750} src={paymentData?.BankSlipUrl} />
            </When>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modal.terms}
        maxWidth={'xl'}
        scroll={'paper'}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ ml: 3, mb: 2, mt: 2 }} fontSize={18} fontWeight={400} color={'text.default'}>
              Termos e política de privacidade
            </Typography>
            <Tooltip title="Fechar popup">
              <IconButton
                onClick={() => toggleModal('terms', false)}
                component="span"
                sx={{ mr: 2, height: 30, width: 30, alignSelf: 'center' }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <DialogContent dividers>
          <Stack display="flex" flexDirection="column" justifyContent={"center"} alignContent={"center"}>
            <iframe width={1024} height={750} src={termLink} />
          </Stack>
        </DialogContent>
      </Dialog>

      <When condition={gtmClass !== undefined}>
        <div id={gtmClass} />
      </When>

    </>
  )
}

export default Home
