import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from 'react-redux';
import { getPersistor } from '@rematch/persist';
import * as dayjs from 'dayjs';
import "@fontsource/poppins";
import 'react-credit-cards/es/styles-compiled.css';

import TagManager from 'react-gtm-module'
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import AppRoutes from './routes';
import { store } from './store';

dayjs.locale('pt-BR')

const persistor = getPersistor();

const gtmId: any = {
  'abrir.cadastromei.org': 'GTM-PBB8BFP',
  'abrir.meicontabil.com': 'GTM-NMKBMXNQ',
  'abrir.portalmeumei.com': 'GTM-5S2SPMN',
  'abrir.empreendedorcnpj.com.br': 'GTM-PPDP6DJZ',
  'abrir.cnpjmei.com.br': 'GTM-WTVX5WMD',
  'abrir.cnpjmei.org': 'GTM-WBVHPTPP'
}[window.location.hostname]

const tagManagerArgs = {
  gtmId,
}

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ThemeConfig>
          <GlobalStyles />
          <AppRoutes />
          <ToastContainer />
        </ThemeConfig>
      </Provider>
    </PersistGate>
  );
}

export default App;
